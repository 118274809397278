<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    v-if="this.$root.mobile"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card>
      <!-- <v-card-title>
        <span class="headline">Edit History</span>
      </v-card-title> -->
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.notes.new") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-form ref="mdHistoryForm" v-model="valid">
            <v-text-field
              v-model="mdNotesForm.summary"
              :label="$t('MF.notes.form.title')"
              outlined
              :rules="summaryRules"
            />
            <!-- details input -->
            <v-textarea
              outlined
              :label="$t('MF.notes.form.details')"
              auto-grow
              v-model="mdNotesForm.details"
              :rules="detailsRules"
              :counter="50"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
           {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" persistent max-width="700px" v-else>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('MF.notes.update')}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="mdNotesForm" v-model="valid">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <v-text-field
                  v-model="mdNotesForm.summary"
                  :label="$t('MF.notes.form.title')"
                  placeholder="Summary"
                  outlined
                  :rules="summaryRules"
                />
              </div>
              <!-- details input -->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <v-textarea
                  outlined
                  :label="$t('MF.notes.form.details')"
                  auto-grow
                  v-model="mdNotesForm.note_body"
                  :rules="detailsRules"
                  :counter="50"
                ></v-textarea>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
           {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
           {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["dialog", "record"],
  data() {
    return {
      error: false,
      submitted: false,
      errorMsg: "",
      itemTypes: [],
      itemTypesLoading: true,
      selectedType: "",
      menu2: false,
      valid: false,
      askStarted: false,
      secondOpinion: false,
      mdNotesForm: {
        summary: "",
        note_body: ""
      },
      summaryRules: [
        (v) => !!v || this.$t('MF.notes.validation.title'),
      ],
      detailsRules: [
        (v) => !!v || this.$t('MF.notes.validation.details'),
        (v) =>
          (v && v.trim().length >= 10) ||
          this.$t('MF.notes.validation.length'),
      ],
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.mdNotesForm.validate()) {
          this.submitted = true;
          await ApiService.update(
            "items/md_notes",
            this.record.id,
            this.mdNotesForm
          );
          this.submitted = false;
          this.$refs.mdNotesForm.reset();
          this.$emit("formSubmitted", "Medicla Note Updated Successfully");
        }
      } catch (error) {
        if (error.message == "") {
          this.error = error;
        } else {
          this.error = this.$t("MF.notes.form.error");
        }
        console.log(error);
      }
    },
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "history_type?fields=id,type,translation.*"
        );
        this.itemTypes = data.data;
        this.itemTypesLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    typeFilter(item, queryText) {
      return item.type.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit("closeDialog");
    }
  },
  mounted() {
    this.getItemTypes();

    this.record &&
      Object.assign(this.mdNotesForm, {
        summary: this.record.summary,
        note_body: this.record.note_body
      });
  }
};
</script>
